<template>
  <div class="reports-dynamic-group-page">
    <h3>Динамика - группа</h3>
    <DynamicGroup />
  </div>
</template>

<script>
import DynamicGroup from "@/components/reports/DynamicGroup"

export default {
  name: "ReportsDynamicGroup",
  components: {
    DynamicGroup,
  },
}
</script>

<style lang="scss" scoped></style>
