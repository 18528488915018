<template>
  <div class="dynamic-group">
    <h3>Линии</h3>

    <div class="hideButtonHolder">
      <a-button
        type="primary"
        class="hideButton"
        @click="toggleTablesVisibility"
      >
        {{ isShowTables ? "Скрыть" : "Показать" }}
      </a-button>
    </div>

    <template v-if="isShowTables">
      <template v-for="(table, index) in tables">
        <a-table
          bordered
          class="table"
          :columns="table.columns"
          :data-source="table.data"
          :key="index"
          :scroll="{ x: 2500 }"
          :pagination="{ defaultPageSize: 10, hideOnSinglePage: true }"
        ></a-table>
      </template>
    </template>

    <Diagrams :list="diagramsList" />
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex"

import Diagrams from "@/components/diagrams/Diagrams"

export default {
  name: "DynamicGroupLine",
  components: {
    Diagrams,
  },
  data() {
    return {
      diagramsList: [
        {
          link: require("../../../assets/reports/dynamic-group/line/diagram1.png"),
        },
        {
          link: require("../../../assets/reports/dynamic-group/line/diagram2.png"),
        },
        {
          link: require("../../../assets/reports/dynamic-group/line/diagram3.png"),
        },
      ],
    }
  },
  mounted() {
    this.fetchTables()
  },
  computed: mapGetters({
    tables: "reportDynamicGroup/lineTables",
    isShowTables: "reportDynamicGroup/isShowTables",
  }),
  methods: {
    ...mapActions({
      fetchTables: "reportDynamicGroup/fetchTables",
    }),
    ...mapMutations({
      toggleTablesVisibility: "reportDynamicGroup/TOGGLE_TABLES_VISIBILITY",
    }),
  },
}
</script>

<style lang="scss" scoped>
.table {
  margin-bottom: 30px;
}

.hideButton {
  width: 100%;
}
</style>
