<template>
  <div class="dynamic-group">
    <Filters />
    <DynamicGroupLine v-if="isLineShow" />
    <DynamicGroupSum v-if="isSumShow" />
  </div>
</template>

<script>
import DynamicGroupLine from "@/components/reports/dynamic-group/DynamicGroupLine"
import DynamicGroupSum from "@/components/reports/dynamic-group/DynamicGroupSum"
import Filters from "@/components/reports/dynamic-group/Filters"

import { mapGetters } from "vuex"

export default {
  components: {
    Filters,
    DynamicGroupLine,
    DynamicGroupSum,
  },
  computed: mapGetters({
    isLineShow: "reportDynamicGroup/isLineShow",
    isSumShow: "reportDynamicGroup/isSumShow",
  }),
}
</script>

<style lang="scss" scoped></style>
